.whiteBackground {
    background-color: #F5F5F5 !important;
}

#margin-bottom{
    margin-bottom: 1.875rem!important;
}

#no-margin {
    margin: 0!important;
}

#perm-accordion > div{
    margin-bottom: 20px!important;
}

#perm-accordion > div[role="heading"]{
    margin-bottom: 0px!important;
    width: fit-content!important;
    z-index: 899;
}

#manage-table-perm-pos{
    margin-left: -1.25rem!important;
    margin-top: -30px !important;
}

#margin-right {
    margin-right: 10px!important;
}

a {
    color: inherit; /* Garde la couleur par défaut du texte */
}