.sideView-container {
  position: absolute;
  right: 0;
  max-width: 450px;
  width: 100%;
  padding: 1rem;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;
  z-index: 1000;

  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(46, 46, 46) !important;
  }

  #teamsHighContrast & {
    background-color: rgb(0, 0, 0) !important;
  }
}

.sideView-premium-true {
  top: 4rem;
  height: calc(100vh - 4rem) !important;
}

.sideView-premium-false {
  top: 5.75rem;
  height: calc(100vh - 5.75rem) !important;
}

.sideView-container-open {
  transform: translateX(0);
}

.sideView-type-button[data-theme~="teamsDefault"] svg path {
  fill: black;
}

.sideView-type-button[data-theme~="teamsDefault"][data-selected~="true"] svg path {
  fill: rgb(255, 255, 255);
}

.sideView-type-button[data-theme~="teamsDefault"][data-selected~="false"] svg path {
  fill: black;
}

.sideView-type-button[data-theme~="teamsDark"] svg path {
  fill: rgb(255, 255, 255);
}

.sideView-form-input-label .ui-form__label::after {
  content: "*";
  color: red;
}

.sideView-text-input-label::after {
  content: "*";
  color: red;
}

.sideView-google-input-container .ui-dropdown,
.ui-dropdown__container,
.ui-list {
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .sideView-container {
    max-width: none;
  }
}

@media screen and (max-width: 570px) {
  .sideView-google-input-container .ui-dropdown__toggle-indicator {
    right: 1.5rem !important;
  }
}
