.fc {
  display: none !important;
}

@media screen and (max-width: 500px) {
  .ui-dropdown__container {
    position: initial !important;
  }

  .ui-dropdown__items-list {
    transform: translate(0px, 65px) !important;
  }
}
