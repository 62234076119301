@use "variables";

html,
body,
#root,
#root > .ui-provider,
#root .ui-provider > div {
  width: 100%;
  height: 100%;
  overscroll-behavior-y: contain;
  overflow: hidden;
}

div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

::-webkit-scrollbar {
  .on-mobile & {
    display: none;
  }
}

#teamsDefault.teamsModern input,
#teamsDefault.teamsModern textarea,
#teamsDefault.teamsModern select,
#teamsDefault.teamsModern button,
#teamsDefault.teamsModern .richTextEditor{
  border:2px solid #F5F5F5 !important;
}

.pos-relative {
  position: relative !important;
}

.ui-provider {
  background: transparent !important;
}

.fill-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fill-width {
  position: absolute;
  left: 0;
  right: 0;
}

.fill-height {
  position: absolute;
  top: 0;
  bottom: 0;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.h-20px {
  height: 20px !important;
}

.hidden {
  display: none !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.animated-content {
  animation: fadein 0.2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.ui-dropdown__item {
  cursor: pointer !important;
}

.ui-dialog__overlay {
  &.open {
    animation: fadein 0.2s;
  }

  &.close {
    animation: fadeout 0.2s;
  }
}

.ui-dialog {
  overflow: visible !important;
}

.delete-btn:not(:disabled) {
  color: white !important;
  fill: white !important;
  background-color: variables.$color-red-light !important;
  border-color: variables.$color-red-light !important;

  &:hover {
    background-color: variables.$color-red-light !important;
    border-color: variables.$color-red-light !important;
  }

  &:active {
    background-color: variables.$color-red-light !important;
    border-color: variables.$color-red-light !important;
  }
}

.icon-auto-fill {
  .ui-button:hover &.icon-btn {
    fill: variables.$color-primary-light !important;
  }

  .ui-button:active &.icon-btn {
    fill: variables.$color-primary-light !important;
  }

  .ui-button &.icon-primary {
    fill: white !important;
  }

  #teamsDefault & {
    fill: black;
  }

  #teamsDark &,
  #teamsHighContrast & {
    fill: white;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.ui-tooltip__content {
  max-width: 100% !important;
}

.top-padding {
  padding-top: variables.$padding;

  .on-mobile & {
    padding-top: variables.$mobile-padding;
  }
}

.bottom-padding {
  padding-bottom: variables.$padding;

  .on-mobile & {
    padding-bottom: variables.$mobile-padding;
  }
}

.y-padding {
  padding-top: variables.$padding !important;
  padding-bottom: variables.$padding !important;

  .on-mobile & {
    padding-top: variables.$mobile-padding !important;
    padding-bottom: variables.$mobile-padding !important;
  }
}

.x-padding {
  padding-left: variables.$padding !important;
  padding-right: variables.$padding !important;

  &.with-scrollbar {
    padding-right: 5px !important;
  }

  .on-mobile & {
    padding-left: variables.$mobile-padding !important;
    padding-right: variables.$mobile-padding !important;

    &.with-scrollbar {
      padding-right: variables.$mobile-padding !important;
    }
  }
}

.ui-card {
  #teamsDefault & {
    background-color: white;
    border: 0;
  }

  #teamsDark & {
    background-color: #2d2c2c;
    border: 0;
  }

  #teamsHighContrast & {
    background-color: black;
  }
}

.stretch-btn {
  padding: 0 !important;
  min-width: 0 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.datepicker-input {
  input {
    padding-right: 12px;
  }
}

.ui-dropdown__items-list {
  min-width: fit-content;
}

.ui-dialog__header {
  width: 100%;
}

.ui-loader__svg {
  &::before {
    background-image: url(./assets/images/custom-loader.svg) !important;
  }
}

.auto-width {
  min-width: fit-content !important;
  width: fit-content !important;
}

.no-shrink {
  flex-shrink: 0 !important;
}

.view-header {
  padding: 15px;
  gap: 15px;
  flex-shrink: 0;
  z-index: 1;
  align-items: center;

  #teamsDefault & {
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }

  #teamsDark & {
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.5);
    background-color: #363636;
  }
}

.view-body {
  position: relative;
  padding: 15px;
  gap: 15px;
  overflow-x: hidden;
  overflow-y: scroll;

  #teamsDefault & {
    background-color: variables.$color-200-light;
  }

  #teamsDark & {
    background-color: #131313;
  }

  .view-body-part {
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
    padding: 15px;
    gap: 15px;

    #teamsDefault & {
      background-color: #fff;
    }

    #teamsDark & {
      background-color: #363636;
    }
  }
}

.view-footer {
  padding: 15px;
  gap: 15px;
  z-index: 1;
  box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.1);
}

.primary-text {
  #teamsDefault & {
    color: variables.$color-primary-light !important;
  }

  #teamsDark & {
    color: variables.$color-primary-dark !important;
  }
}

.info-block-grid {
  width: 100%;
  gap: 15px;
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
}

.custom-icon {
  .ui-button & {
    transform: scale(1.3);
  }

  #teamsDefault & {
    fill: black;
  }

  #teamsDark & {
    fill: white;
  }
}

.ui-skeleton {
  .ui-skeleton__shape,
  .ui-skeleton__line,
  .ui-skeleton__avatar {
    margin: 0 !important;
  }
}

.fix-button-color {
  #teamsDefault.on-mobile & {
    background-color: variables.$color-primary-light !important;
  }

  #teamsDark.on-mobile & {
    background-color: variables.$color-primary-dark !important;
  }
}

.ui-menu.ui-menubutton__menu {
  border-radius: 0 !important;
}

.google-map-info-window {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: auto;
  margin-bottom: 5px;
  margin-right: 10px;
  font-size: 15px;

  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(34, 34, 34);
  }

  #teamsDark & {
    background-color: rgb(34, 34, 34) !important;
    color: white;
  }

  #teamsHighContrast & {
    background-color: rgb(0, 0, 0) !important;
    color: white;
  }
}

.gm-style .gm-style-iw-c {

  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(34, 34, 34) !important;
  }

  #teamsHighContrast & {
    background-color: rgb(0, 0, 0) !important;
  }
}

.gm-ui-hover-effect span{
  #teamsHighContrast & {
    background-color: white !important;
  }
}

.google-map-info-window * {
  margin: 0 0 8px 0 !important;
}

.google-map-info-window a,
.google-map-info-window a:link,
.google-map-info-window a:visited,
.google-map-info-window a:hover,
.google-map-info-window a:active {
  color: #6264a7 !important;
  text-decoration: none !important;
}

.darkContainer .gm-style .gm-style-iw-d,
.darkContainer .gm-style .gm-style-iw-c,
.darkContainer .gm-style .gm-style-iw-t::after {
  background: #1f1f1f;
  color: white;
}

.highContrastContainer .gm-style .gm-style-iw-d,
.highContrastContainer .gm-style .gm-style-iw-c,
.highContrastContainer .gm-style .gm-style-iw-t::after {
  background: black;
  color: white;
}

.darkContainer .gm-style .gm-style-iw-c .gm-ui-hover-effect img,
.highContrastContainer .gm-style .gm-style-iw-c .gm-ui-hover-effect img {
  background-color: white;
  border-radius: 100px;
}

.gm-style .gm-style-iw-d .content {
  margin-right: 10px;
}

.gm-style .gm-style-iw-d .content .siteNotice {
  text-wrap: normal;
  width: 400px !important;
}

.gm-style .gm-style-iw-d .content .sideNotice .labelStartDate {
  font-weight: bold !important;
}

.ui-dialog__overlay{
  z-index: 1000000!important;
}


#teamsDefault.teamsModern input,
#teamsDefault.teamsModern textarea,
#teamsDefault.teamsModern select,
#teamsDefault.teamsModern button,
#teamsDefault.teamsModern .richTextEditor,
#teamsDefault.teamsModern .ui-dropdown{
    border:1px solid #e0e0e0 !important;
    border-radius: 4px;
}

#teamsDefault.teamsModern input:focus
{
    border-bottom: 2px solid rgb(91, 95, 199) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

#teamsDefault.teamsModern .ui-dropdown input,
#teamsDefault.teamsModern .ui-dropdown button {
    border: none !important;
}

