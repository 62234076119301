.alphabetPicker-container {
  z-index: 95;
  position: relative;
  padding: 0 0.5rem;
  height: 100%;
  height: 100vh;
  overflow: scroll;
}

#new-teams .alphabetPicker-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(32, 31, 31) !important;
  }
}

#old-teams .alphabetPicker-container {
  #teamsDefault & {
    background-color: rgb(255, 255, 255) !important;
  }

  #teamsDark & {
    background-color: rgb(0, 0, 0) !important;
  }
}

.alphabetPicker-primary-banner {
  position: absolute;
  top: 0;
  left: -0.5rem;
  width: 3px;
  height: 25px;
  #teamsDefault & {
    background-color: rgb(91, 95, 199);
  }

  #teamsDark & {
    background-color: rgb(98, 100, 167);
  }
}
